import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Communities from "../components/communities"

const CommunitiesPage = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Communities" location={location} />
      <div className="agent-list-header">
        <h1 className="h1-header">
          Our Communities
        </h1>
      </div>

      <Communities
        communitiesSectionSubHeader={data.homeContent.communitiesSectionSubHeader}
        communities={data.allCommunities.nodes}
      />

    </Layout>
  )
}

export default CommunitiesPage

export const query = graphql`
    query MyCommunitiesQuery {
        allCommunities(sort: {fields: homepagePosition, order: ASC}) {
            nodes {
              id
              name
              description
              image {
                url
              }
              homepagePosition
              gallery {
                media {
                  url
                }
              }
              path
            }
        }
        homeContent {
            communitiesSectionSubHeader
            communitiesSectionTitle
        }      
    }
  `
